
import { defineComponent } from "vue-demi";

export default defineComponent({
  name: "RegistrationTokenView",
  props: {
    token: {
      type: String
    }
  },
  async created() {
    if (!this.token) this.$router.push('/');
    await this.axios
      .get('/api/registration/' + this.token)
      .then((res) => {
        this.$store.commit('createNotification', {
          message: 'Ваш аккаунт подтвержден!',
          status: 'success'
        });
      })
      .catch(() => { return });
    this.$router.push('/');
  }
});
